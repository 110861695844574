import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import "../main/slyte.scss";

const BannerMid = ({ bannersMid }) => {
    const viewBanners = () => {
        if (bannersMid.length > 0) {
            return bannersMid.map((item, i) => {
                return (
                    <Col md={6} key={`banner_mid_item_${i}`}>
                        <img
                            className="img-thumbnail"
                            src={`${process.env.REACT_APP_BACKEND_URL}/images/image/` + item.name}
                            alt={item.name}
                            style={{ objectFit: "contain", width: '100%', aspectRatio: '3/2' }}
                        />
                    </Col >
                )
            })
        }
    }

    return (<>
        {bannersMid.length > 0
            ?
            <div className='home-banner-mid'>
                <div className='category mt-2'>
                    <FontAwesomeIcon icon={faImage} className='me-1' /> Hình ảnh hoạt động
                </div>

                <Row className='bg-white ms-0 me-0 '>
                    {viewBanners()}
                </Row>
            </div>
            :
            null
        }
    </>
    )
}

export default BannerMid;




