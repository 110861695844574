import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Footer from './Footer';
import Navbar from './NavBar';
import "../home/homepage.css";

const LayoutClient = () => {
    return (
        <div className='sb-nav-fixed_ bg-light' id="sb-nav-fixed_" >
            <Navbar />

            <main className='page'>
                <Container >
                    <Outlet />
                </Container>

                <Container>
                    <Footer />
                </Container>
            </main>
        </div>
    );
}

export default LayoutClient;

