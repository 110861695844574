import { BrowserRouter, Routes, Route } from "react-router-dom";

import "sweetalert/dist/sweetalert.min";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MasterLayout from "../layouts/user/MasterLayout";
import Error404 from "../components/errors/Error404";
import HomeIndex from "../components/user/home/HomeIndex";
import CartIndex from "../components/user/cart/CartIndex";
import ProductDetails from "../components/user/product/ProductDetails";
import LayoutClient from "../layouts/home/LayoutClient";
import HomePage from "../components/home/main/HomeIndex";
import ProductHomeIndex from "../components/home/Product/ProductHomeIndex";
import BlogIndex from "../components/home/blog/BlogIndex";
import BlogDetail from "../components/home/blog/BlogDetail"
export default function router() {
  return (
    <>
      <ToastContainer />

      <BrowserRouter>
        <Routes>
          <Route path="/order" element={<MasterLayout />}>
            <Route index element={<HomeIndex />} />
            <Route path="gio-hang" element={<CartIndex page={`order`} />} />
            <Route path="danh-muc/:category_slug" element={<HomeIndex />} />
            <Route path="san-pham/:product_slug" element={<ProductDetails page={`order`} />} />
            <Route path="*" element={<Error404 />} />
          </Route>

          <Route path="/" element={<LayoutClient />}>
            <Route index element={<HomePage />} />
            <Route path="danh-muc" element={<ProductHomeIndex />} />
            <Route path="danh-muc/:category_slug/:search" element={<ProductHomeIndex />} />
            <Route path="danh-muc/:category_slug" element={<ProductHomeIndex />} />
            <Route path="san-pham/:product_slug" element={<ProductDetails page={`home`} />} />

            <Route path="gio-hang" element={<CartIndex page={`home`} />} />

            <Route path="tin-tuc/" element={<BlogIndex />} />
            <Route path="tin-tuc/:topicSlug" element={<BlogIndex />} />
            <Route path="bai-viet/:postSlug" element={<BlogDetail />} />

            <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}