import React, { Component } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useCart } from "react-use-cart";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faBars } from '@fortawesome/free-solid-svg-icons';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalUniqueItems: this.props.cart.totalUniqueItems,
        }
    }

    sidenavToggle = (event) => {
        document.getElementById('sb-nav-fixed').classList.toggle('sb-sidenav-toggled');
    }

    render() {
        return (
            <nav className="sb-topnav navbar navbar-expand navbar-dark" id="navbar_top">
                <Link className="navbar-brand ps-3 fw-bold" to="/order/">
                    Xây dựng&nbsp;<span className='text-warning' style={{ 'fontFamily': 'cursive' }}>MinMart</span>
                </Link>

                <Button variant='outline-secondary' className="order-1 order-lg-0" id="sidebarToggle" onClick={this.sidenavToggle}>
                    <FontAwesomeIcon icon={faBars} />
                </Button>

                <ul className="navbar-nav ms-auto ms-md-0 me-4">
                    <Link className="btn btn-outline-light position-relative" to="/order/gio-hang"  >
                        Giỏ hàng <FontAwesomeIcon icon={faCartShopping} />

                        {this.props.cart.totalUniqueItems > 0 ?
                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                {this.props.cart.totalUniqueItems}
                            </span>
                            : null
                        }
                    </Link>
                </ul>
            </nav>
        );
    }
}


function myParams(Component) {
    return props => <Component
        navHook={useNavigate()}
        {...props}
        params={useParams()}
        cart={useCart()}
    />;
}

export default myParams(Navbar);

