import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import '../../assets/user/css/styles.css';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { Container } from 'react-bootstrap';

function MasterLayout() {

    const sidenavToggle = () => {
        const sbNavFixed = document.getElementById('sb-nav-fixed');

        let testClass = sbNavFixed.className;

        if (testClass === 'sb-nav-fixed sb-sidenav-toggled' && window.innerWidth < 992) {
            sbNavFixed.classList.remove('sb-sidenav-toggled');
        }
    }


    return (
        <div className='sb-nav-fixed' id="sb-nav-fixed">
            <Navbar />

            <div id="layoutSidenav">
                <div id="layoutSidenav_nav">
                    <Sidebar />
                </div>

                <div id="layoutSidenav_content" onClick={sidenavToggle}>
                    <Container className='px-3'>
                        <main>
                            <Outlet />
                        </main>
                    </Container>

                    <Footer />
                </div>
            </div>
        </div>
    );
}

// export default MasterLayout;

function myParams(Component) { //Back link AND use "params"
    return props => <Component navHook={useNavigate()} />;
}

export default myParams(MasterLayout);
