import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faLocationDot } from '@fortawesome/free-solid-svg-icons';

$(window).scroll(function () {
    var scrollTop = $(this).scrollTop();
    if (scrollTop > 400) {
        $('#scrollUp').fadeIn(500);
    } else {
        $('#scrollUp').fadeOut(500);
    }
});

const Footer = () => {
    const [info, setInfo] = useState({
        id: null,
        shop_name: null,
        sdt: null,
        email: null,
        address: null,
        logo: null,
        script_footer: null,
        script_header: null,
        slogan: null,
        facebook: null,
        zalo: null,
    });

    useEffect(() => {
        document.title = "Sản phẩm";
        getInfoShop();
    }, []);

    const getInfoShop = () => {
        axios.get(`/getInfoShop`).then((res) => {
            if (res.status === 200) {
                setInfo(res.data.setting);
            }
        }).catch((error) => {
            toast(error.message, { type: 'error' });
        });
    }

    const scrollWin = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <footer className="p-3 mt-auto">
            <div className="container-fluid px-4">
                <Row>
                    <Col md={4}>
                        <h3 className='title d-flex'>
                            {info.logo !== null ?
                                <img className='footer-logo me-1' src={`${process.env.REACT_APP_BACKEND_URL}/images/logo/${info.logo}`} alt="Logo MinMart" />
                                : null
                            }

                            <span className='my-auto'>{info.shop_name}</span>
                        </h3>
                        {info.slogan !== null ? <div dangerouslySetInnerHTML={{ __html: info.slogan }}></div> : null}
                        {info.sdt !== null ? <h6><Link className='footer-link' to={`tel:${info.sdt}`}><span className='fa-solid fa-phone'></span> {info.sdt}</Link></h6> : null}
                        {info.email !== null ? <h6><span className='fa-solid fa-envelope'></span> {info.email}</h6> : null}
                        {info.address !== null ? <h6><span className='fa-solid fa-home'></span> {info.address}</h6> : null}
                    </Col>

                    <Col md={4}>
                        <h3 className='title'>Hỗ trợ</h3>
                        <ul>
                            <li><Link className="text-muted" to="#!">Chính sách bán hàng</Link></li>
                            <li><Link className="text-muted" to="#!">Phương thức vận chuyển</Link></li>
                            <li><Link className="text-muted" to="#!">Chính sách bảo mật</Link></li>
                            <li><Link className="text-muted" to="#!">Điều khoản</Link></li>
                        </ul>

                        <div>
                            <Link target="_blank" to={info.facebook !== null ? info.facebook : '#!'} title="Facebook">
                                <img alt="Facebook" src="/assets/images/icon/facebook-icon.png" />
                            </Link>

                            <Link target="_blank" to={`https://zalo.me/${info.zalo}`} title="Zalo">
                                <img alt="Zalo" src="/assets/images/icon/zalo-icon.png" />
                            </Link>

                            <Link to="#!" title="Instagram">
                                <img alt="Instagram" src="/assets/images/icon/Insta-icon.png" />
                            </Link>

                            <Link to="#!" title="Youtube">
                                <img alt="Youtube" src="/assets/images/icon/Youtube-icon.png" />
                            </Link>
                        </div>

                        <Link to='http://online.gov.vn/Home/WebDetails/107025' title='Đã đăng ký bộ công thương' target='_blank'>
                            <img alt='logoSaleNoti' width="50%" src='/assets/images/icon/logoSaleNoti.png' />
                        </Link>
                    </Col>

                    <Col md={4}>
                        <h3 className='title'>
                            <FontAwesomeIcon icon={faLocationDot} /> Google map
                        </h3>
                        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3928.717785498573!2d105.7534489!3d10.0401309!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31a08957dec4b735%3A0x1c1c9540ce68d4b0!2zTmjDoCBzw6FjaCBNaW5oIEFuaA!5e0!3m2!1svi!2s!4v1683690246176!5m2!1svi!2s"
                            width="100%" height="200px"
                            allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </Col>
                </Row>
            </div>

            <FontAwesomeIcon icon={faAngleUp} className='footer-icon' id="scrollUp" onClick={scrollWin} title="Lên đầu trang" />
        </footer>
    );
}

export default Footer;