import React from 'react';
import { useCart } from "react-use-cart";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

const CartBubble = (props) => {
  const { totalUniqueItems } = useCart();

  return (<>
    {totalUniqueItems > 0 ?
      <span className='hashtagCart '>
        <div className="hashtagCart-circle-fill"></div>
        <Link className="position-relative" to={`${props.page === 'order' ? '/order' : ''}/gio-hang`} title='Giỏ hàng' >
          <FontAwesomeIcon icon={faCartShopping} />
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {totalUniqueItems}
          </span>
        </Link>
      </span>
      : null
    }
  </>)

}

export default CartBubble;