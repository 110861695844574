import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Breadcrumb } from 'react-bootstrap';

class BreadcrumbView extends Component {
    // constructor(props){
    //     super(props);
    // }

    renderRows() {
        if (this.props.link) {
            return this.props.link.map((item, i) => {
                return (
                    <Breadcrumb.Item key={'bre' + i} linkAs={Link} linkProps={{ to: item.href }} active={item.active}>{item.name}</Breadcrumb.Item>
                );
            });
        }
    }

    render() {
        return (
            <Breadcrumb className="pt-2">
                {this.renderRows()}
            </Breadcrumb>
        );
    }
}

export default BreadcrumbView;