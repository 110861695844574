import React from 'react';
import {Link } from 'react-router-dom';

const Error404 = () => {
    return (
        <div id="layoutError_content">
            <main>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="text-center mt-4">
                                <img className="mb-4 img-error" src="/assets/images/404-error-page-not-found.png" alt="error404" />

                                <div className="lead">Không tìm thấy URL được yêu cầu.</div>
                                <Link to="/" className='btn btn-primary'>
                                    <i className="fas fa-arrow-left me-1"></i> Quay lại
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Error404;