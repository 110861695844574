import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from "axios";
// import $ from 'jquery';
import Carousel from 'react-bootstrap/Carousel';
import Category from './Category';
import CartBubble from '../../user/cart/CartBubble';
import Posts from './Posts';
import BannerMid from './BannerMid';
import Loading from '../../user/other/Loading';

// $(window).scroll(function () {
//     if ($(this).scrollTop() > 150) {
//         $('#home_filter').addClass('position-fixed');
//     } else {
//         $('#home_filter').removeClass('position-fixed');
//     }
// })

function HomeIndex() {
    const { category_slug } = useParams();
    const [slider, setSlider] = useState([]);
    const [bannersMid, setBannersMid] = useState([]);
    useEffect(() => {
        document.title = "Trang chủ";
        getBanners();
    }, [category_slug]);

    const getBanners = () => {
        axios.get("/getBanners").then((res) => {
            setSlider(res.data.sliders);
            setBannersMid(res.data.bannersMid);
        }).catch((error) => {
            toast(error.message, { type: 'error' });
        });
    }


    const carouselItems = () => {
        if (slider.length > 0) {
            return slider.map((banner, i) => {
                return (
                    <Carousel.Item interval={3000} key={`bannerItem${i}`}>
                        <img
                            className='carou'
                            src={`${process.env.REACT_APP_BACKEND_URL}/images/image/` + banner.name}
                            alt={banner.name}
                        />
                    </Carousel.Item>
                )
            })

        } else {
            return (
                <Carousel.Item interval={3000} >
                    <div className='carou bg-white position-relative' >
                        <Loading show={true} />
                    </div>
                </Carousel.Item>
            )
        }
    }

    return (
        <>
            <Carousel className='home_slider ' indicators={false}>
                {carouselItems()}
            </Carousel>

            <Posts />

            <BannerMid bannersMid={bannersMid} />

            <Category />

            <CartBubble page={`home`} />
        </>
    )
}

export default HomeIndex;




