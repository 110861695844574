import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const IsLoading = (props) => {

    return (<>
        {props.show ?
            <div className='isLoading'>
                <FontAwesomeIcon icon={faSpinner} spinPulse />
            </div>
            : null
        }
    </>
    )
}

export default IsLoading;