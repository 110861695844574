import React, { useState, useEffect } from 'react';
import { Button, Col, Form, InputGroup, Row, Nav, NavDropdown, Card } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useParams, Link, NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from "axios";
import { useCart } from "react-use-cart";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import '../Product/product.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCartPlus, faMinus, faPlus, faBars } from '@fortawesome/free-solid-svg-icons';
import IsLoading from '../../user/other/IsLoading';
import CartBubble from '../../user/cart/CartBubble';
import BreadcrumbView from '../../user/other/HtmlTag/BreadcrumbView';
import Sidebar from '../../../layouts/home/Sidebar';

$(window).scroll(function () {
    if ($(this).scrollTop() > 150) {
        $('#page_home_filter').addClass('position-fixed');
        if (window.innerWidth > 576) {
            $('.filter-category').removeClass('d-md-none');
            $('.filter-category').addClass('d-md-block');
        }
    } else {

        $('#page_home_filter').removeClass('position-fixed');
        if (window.innerWidth > 576) {
            $('.filter-category').removeClass('d-md-block');
            $('.filter-category').addClass('d-md-none');
        }
    }
})

const ProductHomeIndex = () => {
    const [products, setProducts] = useState([]);
    const { current_page, per_page, total } = products;
    const { addItem, items, updateItemQuantity, updateCartMetadata, metadata, cartTotal } = useCart();
    const [isLoad, setIsLoad] = useState(false);

    const { category_slug, search } = useParams();
    const [keyword, setKeyword] = useState(search || '');
    const [perPage, setPerPage] = useState(30);
    const [sort, setSort] = useState('name_asc');
    const [categorys, setCategorys] = useState([]);
    const [category, setCategory] = useState([]);

    const navigate = useNavigate();


    useEffect(() => {
        document.title = "Danh mục";
        getCategorys();
        getProductsByCategorySlug(category_slug, 1, perPage, search || '');
        window.scrollTo({ top: 0, behavior: 'smooth' });

    }, [category_slug, search]);

    const getCategorys = () => {
        axios.get("/getCategorysToHome").then((res) => {
            setCategorys(res.data)
        }).catch((error) => {
            toast(error.message, { type: 'error' });
        });
    }

    const getProductsByCategorySlug = (categorySlug = 'undefined', pageNumber = 1, perPages = perPage, search = '', sortType = sort) => {

        setIsLoad(true);
        axios.get(`/getProductsToHomeByCategorySlug/${categorySlug}?page=${pageNumber}&per_page=${perPages}&search=${search}&sortType=${sortType}`).then((res) => {
            if (res.status === 200) {
                setCategory(res.data.category);
                setProducts(res.data.products);
            }
            setIsLoad(false);
        }).catch((error) => {
            toast(error.message, { type: 'error' });
            setIsLoad(false);
        });
    }

    const updateCart = () => {
        return updateCartMetadata({
            customer_name: '',
            customer_phone: '',
            customer_email: '',
            province_id: '',
            district_id: '',
            ward_id: '',
            customer_address: '',
            contract_desc: '',
            discount_code: '',
            discount_money: 0,
            discount_type: '',
            discount_value: '',
            totalMoney: cartTotal,
            error_list: [],
        });
    }

    const addToCart = (data) => {
        if (typeof metadata === 'undefined') {
            updateCart();
        } else if (Object.keys(metadata).length === 0) {
            updateCart();
        }

        addItem(data);
        toast('Đã thêm vào giỏ hàng.', { type: 'success', autoClose: 500 });
    }


    const productInterface = () => {
        const productList = products.data || [];

        if (productList.length > 0) {
            return productList.map((item, i) => {
                let itemPrice = [];

                if (item.product_detail.length > 0) {
                    item.product_detail.map((itemDt, iDt) => {
                        const price = parseInt(itemDt.product_price_sale) > 0 ? parseInt(itemDt.product_price_sale) : parseInt(itemDt.product_price);
                        const price_old = parseInt(itemDt.product_price_sale) > 0 ? parseInt(itemDt.product_price) : 0;
                        let info = '';

                        let cartItem = items.filter((itemCart, i) => itemCart.id === itemDt.id);

                        let quantity = cartItem.length > 0 ? cartItem[0].quantity : 0;

                        if (itemDt.info.length > 0) {
                            itemDt.info.map((itemIf, iIf) => {
                                let firstchar = iIf === 0 ? '' : ' - ';
                                info = info + firstchar + itemIf.info_name + ' ' + itemIf.info_dt_name;
                                return itemIf
                            });
                        }

                        itemPrice.push(
                            <div className='item-price' key={`itemPrice${iDt}`}>
                                {itemDt.info.length > 0 ? <div>{info}</div> : <></>}
                                <CurrencyFormat value={price} displayType={'text'} thousandSeparator={true} suffix='₫' renderText={value => <span className="fw-bold fs-6">{value}/{itemDt.product_unit}</span>} />
                                {price_old > 0 ?
                                    <div>
                                        <CurrencyFormat value={price_old} displayType={'text'} thousandSeparator={true} suffix='₫' renderText={value => <span className="me-2 text-muted fst-italic text-decoration-line-through">{value}</span>} />
                                        <span className='fw-bold fst-italic text-danger' >{parseInt((price_old - price) / price_old * 100) * (-1)}%</span>
                                    </div>
                                    : null
                                }
                                <div className='item-btn'>
                                    {cartItem.length === 0 ?
                                        <Button variant='outline-success'
                                            onClick={() => addToCart({
                                                id: itemDt.id,
                                                proName: item.product_name,
                                                proSlug: item.product_slug,
                                                proImg: item.image_main_link,
                                                proType: info,
                                                proUnit: itemDt.product_unit,
                                                price: price,
                                                priceOld: price_old,
                                            })
                                            }
                                        >
                                            <FontAwesomeIcon icon={faCartPlus} />
                                            Thêm giỏ hàng
                                        </Button>
                                        :
                                        <InputGroup className='justify-content-end flex-nowrap item-qty'>
                                            <Button variant='outline-secondary' onClick={(e) => updateItemQuantity(itemDt.id, parseInt(quantity) - 1)} >
                                                <FontAwesomeIcon icon={faMinus} />
                                            </Button>
                                            <Form.Control className='text-end' type='number' min="0" value={quantity} onChange={(e) => updateItemQuantity(itemDt.id, e.target.value)} />
                                            <Button variant='outline-secondary' onClick={(e) => updateItemQuantity(itemDt.id, parseInt(quantity) + 1)}>
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button>
                                        </InputGroup>
                                    }
                                </div>
                            </div >
                        );
                        return itemDt;
                    });
                }

                return (
                    <Col key={`productItem${i}`} className='featured__item' lg={4} md={4} xs={6}>
                        <div className='featured__item__pic'>
                            <Link to={`/san-pham/${item.product_slug}`} title="Xem sản phẩm">
                                {item.image_main_link !== null ? <img src={item.image_main_link} width='100%' alt={item.product_name} /> : null}
                            </Link>
                        </div>
                        <div className='featured__item__text'>
                            <Link className='product__item__title' to={`/san-pham/${item.product_slug}`} title="Xem sản phẩm">
                                {item.product_name}
                            </Link>

                            <div className='product__item__price'>
                                {itemPrice}
                            </div>
                        </div>
                    </Col>
                )
            });
        } else {
            return <h5 className='text-muted'>Hiện tại chưa có sản phẩm!</h5>
        }
    }

    const handlePerPage = (event) => {
        setPerPage(Number(event.target.value));
        getProductsByCategorySlug(category_slug, 1, Number(event.target.value), search || '');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleSearch = (e) => {
        e.preventDefault();
        navigate(`/danh-muc/${category_slug || 'tat-ca'}/${keyword}`);
        setKeyword('');

    }

    const handleSort = (event) => {
        setSort(event.target.value);
        getProductsByCategorySlug(category_slug, 1, perPage, search || '', event.target.value);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const [visible, setVisible] = useState(false);

    const categorysOnOffcanvas = () => {
        if (categorys.length > 0) {
            return categorys.map((item, i) => {
                if (item.category_children.length > 0) {
                    let categoryChilds = [];

                    item.category_children.map((itemChild, iChild) => {
                        categoryChilds.push(<NavDropdown.Item as={NavLink} to={`/danh-muc/${itemChild.category_slug}`} key={`categoryChild${iChild}`} onClick={() => setVisible(false)}>{itemChild.category_name}</NavDropdown.Item>)
                        return itemChild;
                    });

                    return (
                        <NavDropdown id={`offcanvasCategoryItem${i}`} title={item.category_name} key={`offcanvasCategoryItem${i}`} >
                            {categoryChilds}
                        </NavDropdown>
                    )
                } else {
                    return (
                        <Nav.Link as={NavLink} id={`offcanvasCategoryItem${i}`} key={`offcanvasCategoryItem${i}`} to={`/danh-muc/${item.category_slug}`} onClick={() => setVisible(false)}>
                            {item.category_name}
                        </Nav.Link>
                    )
                }
            })
        }
    }

    return (
        <div id="page_home_products">
            <IsLoading show={isLoad} />

            <BreadcrumbView link={[
                { name: 'Trang chủ', active: false, href: '/' },
                { name: category !== null ? category.category_name : `Danh mục`, active: true },
            ]} />

            <Row >
                <Col md={3} className='d-none d-md-block' id="page_products_categorys">
                    <Card>
                        <Card.Body>
                            <Card.Title className="ms-3">
                                <FontAwesomeIcon icon={faBars} /> Danh mục sản phẩm
                            </Card.Title>

                            <Nav className="justify-content-end flex-grow-1 flex-column">
                                <Nav.Link as={NavLink} key={`OffcanvasCategoryItem`} to={`/danh-muc/`} onClick={() => setVisible(false)}>
                                    --Tất cả--
                                </Nav.Link>
                                {categorysOnOffcanvas()}
                            </Nav>

                        </Card.Body>
                    </Card>
                </Col>

                <Col md={9} className='position-relative'>
                    <Row className='page_home_filter' id="page_home_filter" >
                        <Col className='py-2 d-none filter-category'>
                            <Button variant='outline-primary' type="button" onClick={() => setVisible(true)}>
                                <FontAwesomeIcon icon={faBars} /> Menu
                            </Button>
                        </Col>

                        <Col className='py-2 d-none d-md-block filter-search'>
                            <Form onSubmit={handleSearch}>
                                <InputGroup>
                                    <Form.Control value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Tìm kiếm" />
                                    <Button variant='outline-secondary' type='submit'>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Button>
                                </InputGroup>
                            </Form>
                        </Col>

                        <Col className='py-2 filter-sort'>
                            <InputGroup>
                                <InputGroup.Text >Xếp theo</InputGroup.Text>
                                <Form.Select defaultValue={sort} className="form-select" onChange={(e) => handleSort(e)}  >
                                    <option value="id_desc">Mới nhất</option>
                                    <option value="sale_desc">Khuyến mãi</option>
                                    <option value="name_asc">A &#8594; Z</option>
                                    <option value="name_desc">Z &#8594; A</option>
                                    <option value="price_asc">Giá tăng dần</option>
                                    <option value="price_desc">Giá giảm dần</option>
                                </Form.Select>
                            </InputGroup>
                        </Col>

                        <Col className=' py-2 filter-show'>
                            <InputGroup>
                                <InputGroup.Text >Hiển thị</InputGroup.Text>
                                <Form.Select defaultValue={perPage} className="form-select" onChange={(e) => handlePerPage(e)}  >
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row className='px-2  bg-white'>
                        {search ? <Col md={12}><i className='text-muted'>Kết quả tìm kiếm với từ khóa: <strong>{search}</strong> </i> </Col> : ''}

                        {productInterface()}

                        <Pagination
                            activePage={current_page || 0}
                            totalItemsCount={total || 0}
                            itemsCountPerPage={Number(per_page || 0)}
                            onChange={(pageNumber) => {
                                getProductsByCategorySlug(category_slug, pageNumber, perPage, search, sort);
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            itemClass="page-item"
                            linkClass="page-link"
                            firstPageText="Đầu"
                            lastPageText="Cuối"
                        />
                    </Row>
                </Col >
            </Row >

            <CartBubble page={`home`} />

            <Sidebar show={visible} onHide={() => setVisible(false)} />

        </div >
    )
}

export default ProductHomeIndex;




