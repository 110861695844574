import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faAngleDown, faHome } from '@fortawesome/free-solid-svg-icons';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categorys: [],
    }
  }

  async componentDidMount() {
    this.getCategorys();
  }

  getCategorys = () => {
    axios.get("/getCategorys").then((res) => {
      if (res.status === 200) {
        this.setState({ categorys: res.data })
      }
    }).catch((error) => {
      toast(error.message, { type: 'error' });
    });
  }


  categoryInterface = () => {
    if (this.state.categorys.length > 0) {
      return this.state.categorys.map((item, i) => {
        let categoryChild = [];

        if (item.category_children.length > 0) {
          item.category_children.map((itemChild, iChild) => {
            categoryChild.push(
              <NavLink key={`categoryChild${iChild}`} className="nav-link" to={`/order/danh-muc/${itemChild.category_slug}`}>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                {itemChild.category_name}
              </NavLink>
            );
            return itemChild;
          });

          return (
            <div key={`categoryParent${i}`}>
              <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target={`#pagesCollapseCategory${i}`} aria-expanded="false" aria-controls="pagesCollapseMeal" >
                {/* <div className="sb-nav-link-icon"><i className="fa-solid fa-utensils"></i></div> */}
                {item.category_name}
                <div className="sb-sidenav-collapse-arrow">
                  <FontAwesomeIcon icon={faAngleDown} />
                </div>
              </Link>

              <div className="collapse" id={`pagesCollapseCategory${i}`} aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                <nav className="sb-sidenav-menu-nested nav">
                  {categoryChild}
                </nav>
              </div>
            </div>
          )
        } else {
          return (
            <NavLink key={`categoryParent${i}`} className="nav-link" to={`/order/danh-muc/${item.category_slug}`} >
              {item.category_name}
            </NavLink>
          )
        }
      })
    }
  }

  render() {
    return (
      <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion" >
        <div className="sb-sidenav-menu">
          <div className="nav">
            <NavLink className="nav-link" to="/order/">
              <div className="sb-nav-link-icon">
                <FontAwesomeIcon icon={faHome} />
              </div>
              Trang chủ
            </NavLink>

            {/* <div class="sb-sidenav-menu-heading">Danh mục</div> */}

            {this.categoryInterface()}

          </div>
        </div>
        <div className="sb-sidenav-footer py-2">
          <Link to='https://katec.vn/' target='_blank' className="small text-white">Design by Katec</Link>
        </div>
      </nav>
    )
  }
};

export default Sidebar;
