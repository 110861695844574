import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from "axios";
import Carousel from 'react-bootstrap/Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSpinner, faStar } from '@fortawesome/free-solid-svg-icons';
import IsLoading from '../../user/other/IsLoading';
import LazyLoad from 'react-lazyload';

const Category = () => {
    const [listproduct, setproduct] = useState([]);
    const [isLoad, setIsload] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getProductsHome();
    }, []);

    const getProductsHome = () => {
        // setIsload(true);
        axios.get('/getProductsToHome').then((res) => {
            setproduct(res.data);
            setIsload(false);
        }).catch((error) => {
            toast(error.message, { type: 'error' });
            setIsload(false);
        });
    }


    const getimg = (src) => {
        if (src == null)
            return (`/logo512.png`);
        else
            return (`${process.env.REACT_APP_BACKEND_URL}/images/category/` + src);
    }

    const LoadingProduct = () => (
        <Col className='text-center'>
            <h5 ><FontAwesomeIcon icon={faSpinner} spinPulse /> Loading...</h5>
        </Col>
    );

    return (
        <>
            <div className='category mt-2'>
                <FontAwesomeIcon icon={faBars} className='me-1' /> Danh mục sản phẩm
            </div>
            <div className='show_category'>
                <Row
                    className='bg-white mb-4 ms-0 me-0'>
                    <div className='overflow_cate' >
                        {listproduct.length > 0 ?
                            listproduct.map((e, i) => {
                                return (
                                    <Col md={1} sm={3} xs={4} key={`listproduct${i}`}>
                                        <div className='imgCate'>
                                            <Link to={`/danh-muc/` + e.category_slug}>
                                                <img
                                                    className="imgCate_"
                                                    src={getimg(e.category_image)}
                                                    alt={e.category_image}
                                                    style={{ objectFit: "contain", width: '100%', padding: '5px' }}
                                                />
                                            </Link>
                                            <Link className='limited-text' to={`/danh-muc/` + e.category_slug}>   {e.category_name}</Link>
                                        </div>
                                    </Col>
                                )
                            })
                            :
                            <LoadingProduct />
                        }

                    </div>

                    <center className='py-4'>
                        <button className='button-xemthem' onClick={() => navigate(`/danh-muc/`)}>
                            Xem tất cả
                        </button>
                    </center>
                </Row>

                <div className='cate'>
                    {listproduct?.map((e, i) => {
                        let listProducts = e.products;
                        let countProduct = listProducts.length;
                        let viewProducts = [];
                        let carouselItems = [];

                        if (countProduct > 0) {
                            listProducts.map((item, index) => {
                                viewProducts.push(
                                    <Col xs={6} className='p-0 pt-sm-3 px-sm-2' key={`categoryItem${item.id}`}>
                                        <Row className='category_detail_item_ align-items-center'>
                                            <Col md={5} sm={12} xs={12} className='text-center'>
                                                <Link to={'san-pham/' + item.product_slug}>
                                                    <img className='img_cate'
                                                        src={item.image_main_link}
                                                        alt={item.image_main_link}
                                                    />
                                                </Link>
                                            </Col>
                                            <Col md={7} sm={12} xs={12} >
                                                <Link className='limited-text mb-1' to={'san-pham/' + item.product_slug}>
                                                    {item.product_name}
                                                </Link>

                                                <CurrencyFormat className='mb-1' value={item?.product_detail[0]?.product_price} displayType={'text'} thousandSeparator={true} suffix='₫'
                                                    renderText={value => <span className="text-danger">{value}</span>}
                                                />
                                                <div>
                                                    <FontAwesomeIcon icon={faStar} className='text-warning' />
                                                    <FontAwesomeIcon icon={faStar} className='text-warning' />
                                                    <FontAwesomeIcon icon={faStar} className='text-warning' />
                                                    <FontAwesomeIcon icon={faStar} className='text-warning' />
                                                    <FontAwesomeIcon icon={faStar} className='text-warning' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                                return item;
                            });

                            if (countProduct > 1) {
                                carouselItems.push(
                                    <Carousel.Item interval={4000} key={`categoryItem0${e.id}`} >
                                        <Row className='category_detail_item'>
                                            {viewProducts.slice(0, 4).map((itemPro) => { return itemPro })}
                                        </Row>
                                    </Carousel.Item>
                                );
                            }

                            if (countProduct > 4) {
                                carouselItems.push(
                                    <Carousel.Item interval={4000} key={`categoryItem1${e.id}`} >
                                        <Row className='category_detail_item'>
                                            {viewProducts.slice(4, 8).map((itemPro) => { return itemPro })}
                                        </Row>
                                    </Carousel.Item>
                                );
                            }
                        }

                        return (
                            <LazyLoad key={`listproduct2${i}`} placeholder={<LoadingProduct />}>
                                <div className='mb-4 bg-white pb-4  ' key={`listproduct2${i}`}>
                                    <div className='category'>
                                        {e.category_name}
                                    </div>

                                    <Row className='m-0'>
                                        <Col md={4} className='category_banner d-none d-md-block'>
                                            <img src={getimg(e.category_banner)} alt={e.category_banner} />
                                        </Col>

                                        <Col md={8}>
                                            <Carousel className='d-none d-md-block' indicators={false}>

                                                {countProduct > 0 ? carouselItems : <h5 className='text-muted'>Hiện tại chưa có sản phẩm!</h5>}

                                            </Carousel>

                                            <Row className='d-md-none category_product_mb'>
                                                {countProduct > 0 ? viewProducts : <h5 className='text-muted'>Hiện tại chưa có sản phẩm!</h5>}
                                            </Row>
                                        </Col>
                                    </Row>

                                    <center>
                                        <button className='button-xemthem' onClick={() => navigate(`/danh-muc/` + e.category_slug)}>
                                            Xem thêm
                                        </button>
                                    </center>
                                </div>
                            </LazyLoad >
                        )
                    })}
                </div>
            </div>

            <IsLoading show={isLoad} />
        </>
    )
}

export default Category;




