import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Loading = (props) => {

    return (<>
        {props.show ?
            <div className='position-absolute d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%', opacity: '0.8', background: '#d2d2d2' }}>
                <FontAwesomeIcon size='2x' icon={faSpinner} spinPulse />
            </div>
            : null
        }
    </>
    )
}

export default Loading;