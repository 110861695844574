import React from "react";

import Router from "./routes/router";

import axios from "axios";

axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_URL || 'http://127.0.0.1:8000'}/api/`;

axios.defaults.headers.post["Content-Type"] = "*/*";
axios.defaults.headers.post["Accept"] = "*/*";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers["Authorization"] = localStorage.getItem("access_token")
  ? "Bearer " + localStorage.getItem("access_token")
  : "";

export default function App() {
  return <Router />;
}
